<template>
	<b-modal
		centered
		no-close-on-backdrop
		size="lg"
		title="Agregar razones de enfermería"
		v-model="isActive"
		@hidden="handleHidden"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<!-- <pre style="max-width: 10rem">{{ rows }}</pre>
			<pre style="max-width: 10rem">{{ info }}</pre> -->
			<ValidationObserver ref="form">
				<ValidationProvider rules="required" v-slot="{ errors }">
					<b-form-group label="Razón" class="w-100 mb-0">
						<b-input-group class="mb-25">
							<b-form-input v-model="reason" placeholder="Agregar razón..." @keyup.enter="save" />
							<b-input-group-append>
								<b-button size="sm" variant="outline-primary" @click="save">Agregar</b-button>
							</b-input-group-append>
						</b-input-group>
						<small class="text-danger">
							{{ errors[0] }}
						</small>
					</b-form-group>
				</ValidationProvider>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="save">Guardar</b-button>
		</template>
	</b-modal>
</template>

<script>
import reasonsService from "@/services/reasons.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"

export default {
	components: { SpecimenPlate },
	props: {
		show: Boolean,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		reason: null,
	}),
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false

			this.reason = null

			this.$emit("closing")
		},

		async save() {
			if (!(await this.$refs.form.validate())) return
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se agregaran la razón ${this.reason}`,
			})
			if (!isConfirmed) return
			this.isLoading = true

			console.log("ADD")
			await reasonsService.save({ module_id: 4, name: this.reason, parent_id: null })

			this.isLoading = false
			this.handleHidden()
			this.showToast("success", "top-right", "Enfermeria", "CheckIcon", "GUARDADO CON EXITO")
			this.$emit("refresh")
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.isActive = val
			}
		},
	},
}
</script>
