import axios from "@/axios"

const base = "/api/nursing/"

class NurseryDefaultService {
	// specimen
	async getSpecimens({ page, perpage, order, orderby, search, status_id }) {
		const { data } = await axios.get(`${base}specimen`, {
			params: { page, perpage, order, orderby, search, status_id },
		})
		return data
	}
	async getAvailableSpecimens({ search }) {
		const { data } = await axios.get(`${base}specimen/get-specimens`, {
			params: { search },
		})
		return data
	}
	async registerSpecimens({ specimens }) {
		const { data } = await axios.post(`${base}specimen`, { specimens })
		return data
	}
	async changeSpecimenStatus({ status_id, nursing_id, is_dead, comment }) {
		const { data } = await axios.put(`${base}specimen/update-status`, {
			status_id,
			nursing_id,
			is_dead,
			comment,
		})
		return data
	}
	async getTrackingBySpecimen({ id }) {
		const { data } = await axios.get(`${base}specimen/tracking-by-id/${id}`)
		return data
	}
	async getTrackingDashboard({ specimen_id, status_id }) {
		const { data } = await axios.get(`${base}specimen/dashboard`, {
			params: { specimen_id, status_id },
		})
		return data
	}
	// chick
	async getChicks({ page, perpage, order, orderby, search, status_id }) {
		const { data } = await axios.get(`${base}chick`, {
			params: { page, perpage, order, orderby, search, status_id },
		})
		return data
	}
	async getAvailableChicks({ search }) {
		const { data } = await axios.get(`${base}chick/get-chicks`, {
			params: { search },
		})
		return data
	}
	async registerChicks({ chicks }) {
		const { data } = await axios.post(`${base}chick`, { chicks })
		return data
	}
	async changeChickStatus({ status_id, nursing_id, is_dead, status_chick, comment }) {
		const { data } = await axios.put(`${base}chick/update-status`, {
			status_id,
			nursing_id,
			is_dead,
			status_chick,
			comment,
		})
		return data
	}
	async getTrackingByChick({ id }) {
		const { data } = await axios.get(`${base}chick/tracking-by-id/${id}`)
		return data
	}
	// commons
	async getTracking({ id }) {
		const { data } = await axios.get(`${base}tracking-status/${id}`)
		return data
	}
	async getDiagnosticList({ id }) {
		const { data } = await axios.get(`${base}get-diagnostics/${id}`)
		return data
	}
	async getTreatmentList({ id }) {
		const { data } = await axios.get(`${base}get-treatments/${id}`)
		return data
	}
	async insertDiagnostics({ diagnoses, nursing_id }) {
		const { data } = await axios.post(`${base}insert-diagnoses`, {
			diagnoses,
			nursing_id,
		})
		return data
	}
	async insertTreatments({ treatments, nursing_id }) {
		const { data } = await axios.post(`${base}insert-treatment`, {
			treatments,
			nursing_id,
		})
		return data
	}
	async getCounters() {
		const { data } = await axios.get(`${base}counters`)
		return data
	}
	async getMenuCounter() {
		const { data } = await axios.get(`${base}counter-nursing-menu`)
		return data
	}
}
export default new NurseryDefaultService()
