var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"centered":"","no-close-on-backdrop":"","size":"lg","title":_vm.title},on:{"hidden":_vm.handleHidden},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.save}},[_vm._v("Enviar")])]},proxy:true}]),model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('ValidationObserver',{ref:"form"},[_c('b-table-simple',{staticClass:"mt-2",attrs:{"small":"","sticky-header":"80vh"}},[_c('b-thead',{staticClass:"text-center"},[_c('b-tr',[_c('b-th',[_vm._v("Ejemplar")]),_c('b-th',[_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('p',{staticClass:"mb-0"},[_vm._v("Razones")]),_c('feather-icon',{staticClass:"ml-50 text-primary cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"},on:{"click":function($event){_vm.addReason.show = true}}})],1)]),_c('b-th',[_vm._v("Acciones")])],1)],1),_c('b-tbody',{staticClass:"text-center"},_vm._l((_vm.rows),function(row,idx){return _c('b-tr',{key:idx},[_c('b-td',[(_vm.onlyOne)?_c('span',[_c('SpecimenPlate',{attrs:{"specimen":{
										id: _vm.info.specimen.id,
										plate: _vm.info.specimen.plate,
										alias: _vm.info.specimen.alias,
										thumb: _vm.info.specimen.thumb,
										image: _vm.info.specimen.image,
									}}})],1):_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': !!errors[0] },staticStyle:{"min-width":"12rem"},attrs:{"label":"query","clearable":false,"options":_vm.specimenOpts,"reduce":function (opt) { return opt.id; },"selectable":_vm.checkOptionSelectable,"appendToBody":"","calculatePosition":_vm.positionDropdown},on:{"search":_vm.fetchOpts,"option:selecting":_vm.handleSpecimenSelected},model:{value:(row.specimen),callback:function ($$v) {_vm.$set(row, "specimen", $$v)},expression:"row.specimen"}})]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('v-select',{class:{ 'border-danger rounded': !!errors[0] },staticStyle:{"min-width":"20rem"},attrs:{"multiple":"","label":"reason","options":_vm.reasonOpts,"clearable":false,"reduce":function (option) { return option.id; },"appendToBody":"","calculatePosition":_vm.positionDropdown},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('span',{class:{ 'pl-2': !!option.parent_id }},[_vm._v(" "+_vm._s(option.reason)+" ")]),(option.parent_id)?_c('span',[_vm._v(" Sub-motivo de "+_vm._s(option.parent_reason)+" ")]):_vm._e()])]}}],null,true),model:{value:(row.reasons),callback:function ($$v) {_vm.$set(row, "reasons", $$v)},expression:"row.reasons"}})]}}],null,true)})],1),_c('b-td',[_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"danger","disabled":_vm.rows.length < 2},on:{"click":function($event){return _vm.deleteRow(idx)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}})],1)],1)],1)}),1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[(!_vm.onlyOne)?_c('b-button',{staticClass:"btn-icon",attrs:{"size":"sm","variant":"outline-success"},on:{"click":_vm.addRow}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"20"}})],1):_vm._e()],1)],1)],1),_c('AddReasonToNursing',{attrs:{"show":_vm.addReason.show},on:{"closing":function($event){_vm.addReason.show = false
			_vm.getOpts()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }