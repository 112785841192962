<template>
	<b-modal centered no-close-on-backdrop size="lg" :title="title" v-model="isActive" @hidden="handleHidden">
		<b-overlay :show="isLoading">
			<!-- <pre style="max-width: 10rem">{{ rows }}</pre>
			<pre style="max-width: 10rem">{{ info }}</pre> -->
			<ValidationObserver ref="form">
				<b-table-simple class="mt-2" small sticky-header="80vh">
					<b-thead class="text-center">
						<b-tr>
							<b-th>Ejemplar</b-th>
							<b-th>
								<div class="d-flex align-items-center justify-content-center">
									<p class="mb-0">Razones</p>
									<feather-icon
										@click="addReason.show = true"
										icon="PlusIcon"
										class="ml-50 text-primary cursor-pointer"
										size="18"
									/>
								</div>
							</b-th>
							<b-th>Acciones</b-th>
						</b-tr>
					</b-thead>
					<b-tbody class="text-center">
						<b-tr v-for="(row, idx) in rows" :key="idx">
							<b-td>
								<span v-if="onlyOne">
									<SpecimenPlate
										:specimen="{
											id: info.specimen.id,
											plate: info.specimen.plate,
											alias: info.specimen.alias,
											thumb: info.specimen.thumb,
											image: info.specimen.image,
										}"
									/>
								</span>
								<ValidationProvider rules="required" v-slot="{ errors }" v-else>
									<v-select
										label="query"
										style="min-width: 12rem"
										:class="{ 'border-danger rounded': !!errors[0] }"
										:clearable="false"
										:options="specimenOpts"
										:reduce="(opt) => opt.id"
										:selectable="checkOptionSelectable"
										v-model="row.specimen"
										appendToBody
										:calculatePosition="positionDropdown"
										@search="fetchOpts"
										@option:selecting="handleSpecimenSelected"
									/>
								</ValidationProvider>
							</b-td>
							<b-td>
								<ValidationProvider rules="required" v-slot="{ errors }">
									<v-select
										multiple
										label="reason"
										style="min-width: 20rem"
										:class="{ 'border-danger rounded': !!errors[0] }"
										:options="reasonOpts"
										:clearable="false"
										:reduce="(option) => option.id"
										v-model="row.reasons"
										appendToBody
										:calculatePosition="positionDropdown"
									>
										<template v-slot:option="option">
											<div class="d-flex justify-content-between">
												<span :class="{ 'pl-2': !!option.parent_id }">
													{{ option.reason }}
												</span>
												<span v-if="option.parent_id">
													Sub-motivo de {{ option.parent_reason }}
												</span>
											</div>
										</template>
									</v-select>
								</ValidationProvider>
							</b-td>
							<b-td>
								<b-button
									class="btn-icon"
									size="sm"
									variant="danger"
									@click="deleteRow(idx)"
									:disabled="rows.length < 2"
								>
									<feather-icon icon="TrashIcon" />
								</b-button>
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
				<div class="d-flex justify-content-end">
					<b-button class="btn-icon" size="sm" variant="outline-success" @click="addRow" v-if="!onlyOne">
						<feather-icon icon="PlusIcon" size="20" />
					</b-button>
				</div>
			</ValidationObserver>
		</b-overlay>
		<template #modal-footer>
			<b-button variant="success" @click="save">Enviar</b-button>
		</template>

		<AddReasonToNursing
			:show="addReason.show"
			@closing="
				addReason.show = false
				getOpts()
			"
		/>
	</b-modal>
</template>

<script>
import reasonsService from "@/services/reasons.service"
import nurseryService from "@/services/nursery/default.service"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import AddReasonToNursing from "./AddReasonToNursing.vue"

export default {
	components: { SpecimenPlate, AddReasonToNursing },
	props: {
		show: Boolean,
		info: Object,
	},
	data: () => ({
		isActive: false,
		isLoading: false,
		title: null,
		rows: [
			{
				specimen: null,
				reasons: [],
			},
		],
		specimenOpts: [],
		selectedSpecimens: [],
		reasonOpts: [],
		addReason: {
			show: false,
		},
	}),
	computed: {
		onlyOne() {
			// objeto con id,alias y placa del specimen
			return !!this.info && !!this.info.specimen
		},
	},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.rows = [
				{
					specimen: null,
					reasons: [],
				},
			]
			this.specimenOpts = []
			this.selectedSpecimens = []
			this.$emit("closing")
		},
		async getOpts() {
			this.$emit("loading", true)
			this.is
			const reasons = await reasonsService.getByModule(4, 6)
			this.reasonOpts = reasons
			this.$emit("loading", false)
		},
		addRow() {
			this.rows.push({
				specimen: null,
				reasons: [],
			})
		},
		deleteRow(idx) {
			this.rows.splice(idx, 1)
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		// search
		fetchOpts(search, loading) {
			this.findSpecimens(search, loading, this.setOpts)
		},
		findSpecimens: _.debounce(async (search, loading, setter) => {
			loading(true)
			const { data } = await nurseryService.getAvailableSpecimens({ search })
			// para que el search del select tenga un campo para buscar por placa y alias a la vez
			const fixedData = data.map((opt) => ({ ...opt, query: opt.plate + "," + (opt.alias || "---") }))
			setter(fixedData)
			loading(false)
		}, 500),
		setOpts(data) {
			const existingIds = this.selectedSpecimens.map((c) => c.id)
			const withoutDuplicateds = data.filter((opt) => !existingIds.includes(opt.id))
			this.specimenOpts = [...withoutDuplicateds, ...this.selectedSpecimens]
		},
		handleSpecimenSelected(cintillo) {
			const existingIds = this.selectedSpecimens.map((opt) => opt.id)
			if (existingIds.includes(cintillo.id)) return
			this.selectedSpecimens.push(cintillo)
			// para limpiar especimens que no se usan al cambiar de opcion, se puede hacer un filter con los ids de los rows
		},
		async save() {
			if (!(await this.$refs.form.validate())) return
			if (this.info && this.info._isTournamentResults) {
				// solo cuando se usa el modal en tournament/ResultsModal
				const row = this.rows[0]
				this.$emit("set-treatments", { reasons: row.reasons })
				this.handleHidden()
				return
			}
			const { isConfirmed } = await this.showConfirmSwal({
				text: `Se agregaran ${this.rows.length} ejemplar(es) a enfermeria`,
			})
			if (!isConfirmed) return
			this.isLoading = true
			const specimens = this.rows.map((row) => ({ id: row.specimen, reasons: row.reasons }))
			await nurseryService.registerSpecimens({ specimens })
			this.isLoading = false
			this.handleHidden()
			this.showToast("success", "top-right", "Enfermeria", "CheckIcon", "GUARDADO CON EXITO")
			this.$emit("refresh")
		},
		checkOptionSelectable(opt) {
			const existingIds = this.rows.map((row) => row.specimen)
			if (existingIds.includes(opt.id)) return false
			return true
		},
	},
	watch: {
		show(val) {
			if (val) {
				this.getOpts()
				this.isActive = val
				if (this.onlyOne) {
					this.rows = [
						{
							specimen: this.info.specimen.id,
							reasons: [],
						},
					]
					this.title = `Enviar ejemplar ${this.info.specimen.alias || this.info.specimen.plate} A ENFERMERIA`
				} else {
					this.title = "ENVIAR EJEMPLARES A ENFERMERIA"
				}
			}
		},
	},
}
</script>
